<template>
    <div class="right-cell">
        <div class="cell-view">
            <div class="cell-title">ROSEONLY Commercial Co. Ltd</div>
            <div class="cell-spant">Add：HONGKONG HARBOUR CITY,TSIM SHA TSUI,KOWLOON, SHOP 3224,LEVEL3.GATEWAY ARCADE</div>
            <div class="cell-spant">Tel：+852 34273301</div>
            <div class="cell-spant">Email：service@roseonly.com</div>
        </div>

        <div class="cell-view">
            <div class="cell-title">Corpoerate</div>
            <div class="cell-spant">Email：marketing@roseonly.com</div>
        </div>
    </div>
</template>
  
  
  
  
<script name="Maintenance" setup>

import { onMounted } from 'vue'

onMounted(() => {
    scrollTop()
})
const scrollTop = () => {
    window.scrollTo(0, 0); // 将页面滚动到顶部
}
</script>
  
<style lang="scss" scoped>
.right-cell {
    font-size: 14px;

    .cell-title {
        // height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-weight: 700;
    }

    .cell-view {
        margin: 20px 0;
        padding-bottom: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #d9d9d9;
    }

    .cell-spant {
        // height: 30px;
        line-height: 30px;
    }
}
</style>